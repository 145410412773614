<template>
  <a-icon :component="component"></a-icon>
</template>

<script>
export default {
  name: 'GoIcon',
  data () {
    return {
      component: {
        template: `
          <svg viewBox="0 0 1028 1024" width="1em" height="1em">
            <path d="M135.516 187.013a64.392 64.392 0 0 1 43.086 16.552l289.614 260.727c26.527 23.882 28.742 64.825 4.947 91.45a64.643 64.643 0 0 1-4.947 4.965L178.602 821.434c-26.528 23.882-67.323 21.659-91.118-4.965a64.893 64.893 0 0 1-16.492-43.242V251.773c0-35.766 28.888-64.76 64.524-64.76z m462.353 0a64.392 64.392 0 0 1 43.085 16.552l289.614 260.727c26.528 23.882 28.743 64.825 4.948 91.45a64.643 64.643 0 0 1-4.948 4.965L640.954 821.434c-26.528 23.882-67.322 21.659-91.118-4.965a64.893 64.893 0 0 1-16.492-43.242V251.773c0-35.766 28.889-64.76 64.525-64.76z" fill="currentColor" p-id="103842"></path>
          </svg>
        `
      }
    }
  }
}
</script>
