<template>
  <div class="resource-tab-container">
    <a-tabs :tabBarGutter="16" v-model="activeName">
      <a-tab-pane v-for="(item, index) in list" :key="item.name">
        <template slot="tab">
          <div class="container" :style="{ background: getBg(index) }">
            <img :src="getImgUrl(item.name)" width="40" height="30" />
            <div @click.stop="$emit('goTable', item.name)" class="value">{{ item.total }}</div>
            <div style="margin-bottom: 10px">
              {{ getType(item.name) }}
            </div>
            <!-- 告警列表 -->
            <div style="padding: 0 8px">
              <div
                class="alert-list"
                v-for="statusItem in item.status"
                :key="statusItem.name"
              >
                <div class="severity">
                  <div
                    class="dot"
                    :style="{ background: getStatusColor(statusItem.name) }"
                  ></div>
                  <div class="text">{{ $t(`source_status.${statusItem.name}`) }}</div>
                </div>
                <div
                  @click.stop="$emit('goTableWithStatus', [item.name, statusItem.name])"
                  :style="{
                    fontSize: '14px',
                    marginTop: '4px',
                    color: getStatusColor(statusItem.name),
                    cursor: 'pointer'
                  }"
                >
                  {{statusItem.value}}
                </div>
              </div>
            </div>
            <div @click.stop="$emit('goTable', item.name)" style="background: #d4d4d447; margin: 0 -16px; border-radius: 0 0 8px 8px; margin-top: 10px;font-size: 13px; color: #6de3e1; cursor: pointer;">
              <go-icon></go-icon>
            </div>
          </div>
        </template>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import {
  redColor,
  greenColor,
  orangeColor,
  grayColor,
  backgroundColors,
  sourceStatuses
} from '@/utils/const.js'
import GoIcon from '../icon/GoIcon'

export default {
  name: 'ResourceTab',
  components: {
    GoIcon
  },
  props: {
    sourceType: {
      type: String,
      default: 'network_device'
    },
    dataSource: {
      type: Array,
      default: () => []
    },
    deviceType: {
      type: [String, Array]
    }
  },
  data () {
    return {
      backgroundColors,
      sourceStatuses,
      activeName: '',
      list: []
    }
  },
  mounted () {
    if (this.dataSource.length) {
      this.list = JSON.parse(JSON.stringify(this.dataSource)).sort((a, b) => b.total * 1 - a.total * 1)
      this.activeName = this.list[0].name
    }
  },
  methods: {
    getBg (index) {
      if (index + 1 > this.backgroundColors.length) {
        index = index % this.backgroundColors.length
      }
      return this.backgroundColors[index]
    },
    getStatusColor (status) {
      if (status === 'normal') return greenColor
      else if (status === 'abnormal') return redColor
      else if (status === 'alert') return orangeColor
      return grayColor
    },
    getImgUrl (v) {
      let imgUrl
      try {
        imgUrl = require(`@/assets/icons/${this.sourceType}-types/${v}.svg`)
      } catch (error) {
        imgUrl = require(`@/assets/icons/${this.sourceType}.svg`)
      }
      return imgUrl
    },
    getType (type) {
      if (this.sourceType === 'storage' || this.deviceType || this.sourceType === 'server') return this.$t(`hardware_vendor.${type}`)
      return this.$t(`${this.sourceType}_type.${type}`)
    }
  },
  watch: {
    dataSource: {
      handler (v) {
        if (v.length) {
          this.list = JSON.parse(JSON.stringify(v)).sort((a, b) => b.total * 1 - a.total * 1)
          this.activeName = this.list[0].name
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="less">
.resource-tab-container {
  .ant-tabs-bar {
    border: none;
    .ant-tabs-nav {
      .ant-tabs-tab-active {
        text-shadow: none;
      }
      .ant-tabs-tab {
        padding: 0;
        margin: 0;

        .container {
          height: 274px;
          width: 180px;
          text-align: center;
          border-radius: 8px;
          padding: 20px 16px;

          .value {
            font-weight: 400;
            font-size: 32px;
            margin: 4px 0 2px;
          }

          .alert-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 0 2px 0px;
            cursor: default;

            .severity {
              .dot {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                display: inline-block;
              }
              .text {
                display: inline-block;
                color: rgba(0, 0, 0, 0.65);
                font-size: 12px;
                width: 48px;
                margin-left: 6px;
              }
            }
          }
        }
      }
      .ant-tabs-ink-bar {
        display: none !important;
      }
    }
  }
}
</style>
