<template>
  <div :id="chartId"></div>
</template>

<script>
// 堆叠柱状图
import { Chart } from '@antv/g2'
import { chartColors } from '@/utils/const'
import { getIntegerTicks, StatusColor } from '@/utils'

const chart = {}

export default {
  name: 'StackedColumnChart',
  props: {
    chartId: {
      type: String,
      required: true
    },
    chartData: {
      type: Array,
      default: () => []
    },
    height: {
      type: Number,
      default: 240
    },
    axis: {
      type: Object,
      default: () => { return { x: 'name', y: 'value' } }
    },
    colorType: {
      type: String,
      default: 'type'
    },
    colors: {
      type: Array,
      default: () => chartColors
    },
    size: {
      type: Number,
      default: 24
    },
    isSeverityChart: {
      type: Boolean,
      default: false
    },
    showLegend: {
      type: Boolean,
      default: true
    },
    legendPosition: {
      type: String,
      default: 'top'
    }
  },
  mounted () {
    chart[this.chartId] = new Chart({
      container: this.chartId,
      autoFit: true,
      height: this.height
    })

    chart[this.chartId].scale(this.axis.y, {
      min: 0,
      nice: true,
      tickMethod: getIntegerTicks
    })
    if (this.showLegend) {
      chart[this.chartId].legend({
        position: this.legendPosition
      })
    } else {
      chart[this.chartId].legend(false)
    }

    chart[this.chartId].axis(this.axis.y, {
      grid: {
        line: {
          style: {
            stroke: '#cccccc',
            strokeOpacity: 0.8,
            lineWidth: 1,
            lineDash: [4]
          }
        }
      },
      label: {
        style: {
          fill: '#808080'
        }
      }
    })

    chart[this.chartId].axis(this.axis.x, {
      tickLine: null,
      label: {
        autoHide: false,
        autoRotate: true,
        autoEllipsis: true
      }
    })

    chart[this.chartId].tooltip({
      shared: true,
      showMarkers: false
    })
    this.render()
  },
  methods: {
    render () {
      chart[this.chartId].clear()
      if (!this.chartData.length) chart[this.chartId].changeVisible(false)
      else {
        chart[this.chartId].changeVisible(true)
        if (this.isSeverityChart) {
          chart[this.chartId]
            .interval()
            .position(`${this.axis.x}*${this.axis.y}`)
            .size(this.size)
            .color('type', v => {
              return StatusColor(v)
            })
            .adjust('stack')
            .label('value', (v) => {
              if (v !== 0) return v
              return undefined
            }, {
              type: 'interval',
              position: 'middle',
              style: {
                fontSize: 11,
                fill: '#fff',
                fontWeight: 500
              }
            })
        } else {
          chart[this.chartId]
            .interval()
            .position(`${this.axis.x}*${this.axis.y}`)
            .size(this.size)
            .color(this.colorType, this.colors)
            .adjust('stack')
            .label('value', (v) => {
              if (v !== 0) return v
              return undefined
            }, {
              type: 'interval',
              position: 'middle',
              style: {
                fontSize: 11,
                fill: '#fff',
                fontWeight: 500
              }
            })
        }

        chart[this.chartId].interaction('active-region')
        chart[this.chartId].data(this.chartData)
        chart[this.chartId].render()
      }
    }
  },
  watch: {
    chartData () {
      this.render()
    }
  }
}
</script>
